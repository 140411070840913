import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from "rxjs";
import { AuthService } from "./auth.service";
import * as moment from "moment";

@Injectable()
export class BookingService {

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  search(search) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.authService.currentUser.value.basicAuthToken}`
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "booking/search?search=" + search, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        reject()
      });
    });
  }

  getAppointments(appointmentDate, search) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.authService.currentUser.value.basicAuthToken}`
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "booking/getappointments?appointmentDate=" + appointmentDate + "&search=" + search, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        reject()
      });
    });
  }

  get(appointmentDate, search) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.authService.currentUser.value.basicAuthToken}`
    };

    appointmentDate = moment(appointmentDate).format("YYYY-MM-DD");

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "booking/get?appointmentDate=" + appointmentDate + "&search=" + search, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        reject()
      });
    });
  }

  getById(id) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.authService.currentUser.value.basicAuthToken}`
    };

    return new Promise((resolve, reject) => {
      this.http.get<any>(environment.apiUrl + "booking/getbyid?id=" + id, { headers: headers }).subscribe(data => {
        resolve(data);
      }, (error) => {
        reject()
      });
    });
  }

  save(bookingDto) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.authService.currentUser.value.basicAuthToken}`
    };

    // format the dto
    bookingDto.appointmentAt = moment(bookingDto.appointmentAt).format("YYYY-MM-DDTHH:mm:ssZ");


    return new Promise((resolve, reject) => {
      if (bookingDto.id == null) {
        this.http.post<any>(environment.apiUrl + "booking/post", bookingDto, { headers: headers }).subscribe(data => {
          resolve(data);
        }, (error) => {
          reject()
        });
      } else {
        this.http.put<any>(environment.apiUrl + "booking/put", bookingDto, { headers: headers }).subscribe(data => {
          resolve(data);
        }, (error) => {
          reject()
        });
      }
    });
  }

  delete(id) : Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.authService.currentUser.value.basicAuthToken}`
    };

    return new Promise((resolve, reject) => {
      this.http.delete<any>(environment.apiUrl + "booking/delete?id=" + id, { headers: headers }).subscribe(data => {
        resolve(true);
      }, (error) => {
        reject()
      });
    });
  }
}